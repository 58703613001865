@import "./fontawesome.min.css";
@import "./solid.min.css";

:root {
  --color-background: #787878;
  --color-primary: #10b759;
  --font-family-base: Poppin, sans-serif;
  --font-size-h1: 1.25rem;
  --font-size-h2: 1.35rem;
}

* {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: #fae3ea;
  background-color: var(--color-background);
  display: block;
  font-family: Poppin, sans-serif;
  font-family: var(--font-family-base);
  line-height: 1.5;
  margin: 0;
  min-height: 100vh;
  padding: 5vmin;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
  place-items: center;
}

button {
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 1.25rem;
  font-size: var(--font-size-h1);
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0;
}

h2 {
  font-size: 1rem;
  font-size: var(--font-size-h2);
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5em;
}

legend {
  font-weight: 600;
  margin-bottom: 0.5em;
  padding: 0;
}

input {
  border: 0;
  color: inherit;
  font: inherit;
}

input[type="radio"] {
  accent-color: #fc8080;
  accent-color: var(--color-primary);
}

.align {
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
  place-items: center;
}

.button {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fc8080;
  background-color: var(--color-primary);
  border-radius: 999em;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-gap: 0.5em;
  gap: 0.5em;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 1em;
  padding-right: 1em;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.button:focus,
.button:hover {
  background-color: #07883f;
}

.button--full {
  width: 100%;
}

.card {
  border-radius: 1em;
  background-color: #fc8080;
  background-color: var(--color-primary);
  color: #fff;
  padding: 1em;
}

.form {
  display: block;
}

.form__radios {
  display: grid;
  grid-gap: 1em;
  gap: 1em;
}

.form__radio {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fefdfe;
  border-radius: 1em;
  -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.0625);
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.0625);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  // padding: 1em;
  padding-left: 1em;
  padding-right: 1em;
}

.form__radio:hover {
  background-color: #ececec;
}

.form__radio.active {
  background-color: #aff7ad;
}

.form__radio label {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-gap: 1em;
  gap: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.form__radio label:hover {
  cursor: pointer;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
}

.icon {
  height: "auto";
  display: inline-block;
  fill: currentColor;
  width: 130px;
  vertical-align: middle;
}

.iphone {
  background-color: #fbf6f7;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fbf6f7),
    to(#fff)
  );
  background-image: -o-linear-gradient(top, #fbf6f7, #fff);
  background-image: linear-gradient(to bottom, #fbf6f7, #fff);
  border-radius: 2em;
  height: 740px;
  -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.0625);
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.0625);
  width: 375px;
  overflow: auto;
  padding: 2em;
  margin: auto;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.text-center {
  text-align: center !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.dataTables_wrapper div.dataTables_paginate ul.pagination,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.text-muted {
  color: #999999 !important;
}

.rnc__notification-container--top-right {
  right: 70px !important;
  top: 0px !important;
}

.text-muted2 {
  color: #818181 !important;
}

.bankcard {
  width: 320px;
  height: 200px;
  border-radius: 10px;
  perspective: 1000px;

  &-inner {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: transform 600ms ease;
    transform-style: preserve-3d;
    box-shadow: 0 0 25px 2px rgba(black, 0.2);
  }

  &-front {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    backface-visibility: hidden;
    background: linear-gradient(321.03deg, #01adef 0%, #0860bf 91.45%);
  }

  &-front {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: transform 300ms ease-in-out;
  }

  &-bg {
    position: absolute;
    top: -20px;
    right: -120px;
    width: 380px;
    height: 250px;
    background: linear-gradient(321.03deg, #01adef 0%, #0860bf 91.45%);
    border-top-left-radius: 100%;

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      right: -80px;
      width: 380px;
      height: 250px;
      background: linear-gradient(321.03deg, #01adef 0%, #0860bf 91.45%);
      border-top-left-radius: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      top: -20px;
      right: -120px;
      width: 380px;
      height: 250px;
      background: linear-gradient(321.03deg, #01adef 0%, #0860bf 91.45%);
      border-top-left-radius: 100%;
    }
  }

  &-glow {
    position: absolute;
    top: -140px;
    left: -65px;
    height: 200px;
    width: 400px;
    background: rgba(#00b7ff, 0.4);
    filter: blur(10px);
    border-radius: 100%;
    transform: skew(-15deg, -15deg);
  }

  &-contactless {
    position: absolute;
    right: 15px;
    top: 10px;
    transform: scale(0.5);
  }

  &-chip {
    position: absolute;
    top: 25px;
    left: 25px;
    width: 40px;
    height: 31px;
    border-radius: 5px;
    background-color: #ffda7b;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: 49%;
      top: -7%;
      transform: translateX(-50%);
      background: #ffda7b;
      border: 1px solid #a27c1f;
      width: 25%;
      height: 110%;
      border-radius: 100%;
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 30%;
      left: -10%;
      background: transparent;
      border: 1px solid #a27c1f;
      width: 120%;
      height: 33%;
    }
  }

  &-number {
    position: absolute;
    text-align: center;
    bottom: 80px;
    width: 100%;
    color: white;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2em;
    filter: drop-shadow(1px 1px 1px rgba(black, 0.3));
  }

  .bankname {
    text-align: center;
    position: absolute;
    top: 30px;
    color: white;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    text-shadow: (1px 1px 10px rgba(black, 0.3));
  }

  .amount {
    text-align: center;
    position: absolute;
    bottom: 25px;
    color: white;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    text-shadow: (1px 1px 10px rgba(black, 0.3));
  }
}

.countdownTimer div {
  margin: 0 auto;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-success {
  color: #085f2e;
  background-color: #cff1de;
  border-color: #bcebd1;
}
.alert-success hr {
  border-top-color: #a8e5c4;
}
.alert-success .alert-link {
  color: #043017;
}

.alert-warning {
  color: #836203;
  background-color: #fef2cd;
  border-color: #feecb9;
}
.alert-warning hr {
  border-top-color: #fee5a0;
}
.alert-warning .alert-link {
  color: #513d02;
}
